@import '@root/colours.scss';

$datepicker__selected-color: $color-pink;
$datepicker__text-color: $color-blue;
$datepicker__header-color: $color-pink;
$datepicker__background-color: $color-grey-lightest;
$datepicker__border-color: $color-blue;
$datepicker__muted-color: $color-grey-lighter;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
    &,
    .react-datepicker__current-month {
        font-size: 12px !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
        margin: 5px;
    }

    .react-datepicker__day--keyboard-selected {
        background: none;

        &:not(.react-datepicker__day--disabled) {
            color: $color-blue;
        }
    }

    .react-datepicker__navigation-icon {
        &::before {
            border-color: $color-blue;
        }
    }
}
