@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_popover.scss';

@import '@root/colours.scss';

.input-group {
    button {
        border-width: 1px !important;
        border-radius: 0 4px 4px 0 !important;
    }

    svg {
        vertical-align: middle;
    }

    .error {
        + .input-group-btn {
            > button {
                background-color: $color-red-lighter;
                border-color: $color-red;
            }
        }
    }
}