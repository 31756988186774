@font-face {
    font-family: 'Arial Rounded';
    font-display: swap;
    src: url('/fonts/ARLRDBD.woff2') format('woff2'),
        url('/fonts/ARLRDBD.woff') format('woff'),
        url('/fonts/ARLRDBD.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: url('/fonts/Poppins-Regular.woff2') format('woff2'),
        url('/fonts/Poppins-Regular.woff') format('woff'),
        url('/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: url('/fonts/Poppins-Medium.woff2') format('woff2'),
        url('/fonts/Poppins-Medium.woff') format('woff'),
        url('/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: url('/fonts/Poppins-Bold.woff2') format('woff2'),
        url('/fonts/Poppins-Bold.woff') format('woff'),
        url('/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
}
