@import '@root/colours.scss';

table {
    &.comparison {
        th {
            vertical-align: top;
        }

        td {
            vertical-align: bottom;
        }

        thead {
            tr {
                &:first-child {
                    th {
                        &:first-child {
                            border-top-left-radius: 8px;
                        }

                        &:last-child {
                            border-top-right-radius: 8px;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                &:last-child {
                    border-bottom-width: 2px !important;
                }
            }
        }
    }
}

.bg-plan-lavender {
    background-color: #9080d0 !important;

    &,
    & > * {
        color: $color-white !important;
    }
}

.bg-plan-yellow {
    background-color: $color-yellow !important;

    &,
    & > * {
        color: $color-blue !important;
    }
}

.bg-plan-blue {
    background-color: #007fbf !important;

    &.offer {
        background-color: #ff5f3b !important;

        .small {
            color: $color-blue !important;
        }
    }

    &,
    & > * {
        color: $color-white !important;
    }
}

.bg-plan-yellow-light {
    background-color: lighten($color-yellow, 7%) !important;
}

.bg-plan-blue-light {
    background-color: #00a7e0 !important;
}

.bg-plan-yellow-lighter {
    background-color: lighten($color-yellow, 15%) !important;
}

.bg-plan-blue-lighter {
    background-color: #0cf !important;
}

.bg-plan-yellow-lightest {
    background-color: rgba($color-yellow, .2) !important;
}

.border {
    &.border-blue-lighter {
        border-color: #0cf !important;
    }

    &.border-yellow-lighter {
        border-color: lighten($color-yellow, 15%) !important;
    }
}
