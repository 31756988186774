@import 'mixins.scss';
@import 'colours.scss';
@import 'fonts.scss';

$body-color: $color-blue;
$font-family-base: 'Poppins', arial, helvetica, sans-serif;
$headings-color: $color-purple;
$headings-font-family: 'Arial Rounded', 'Arial Black', Gadget, sans-serif;
$headings-font-weight: bold;
$border-radius-lg: .75rem;
$btn-border-radius: 8px;
$navbar-light-color: $color-white;
$navbar-light-toggler-border-color: $color-white;
$navbar-toggler-focus-width: 0rem;
$theme-colors: (
    'primary': $color-blue,
    'secondary': $color-grey-light,
    'success': $color-green,
    'info': $color-blue-light,
    'warning': $color-yellow,
    'danger': $color-red,
    'light': $color-grey-lightest,
    'dark': $color-grey,
    'pink': $color-pink,
    'purple': $color-purple,
);

@import '~bootstrap/scss/bootstrap.scss';

// Main Elements

body {
    background-color: $color-purple;
    background-image: url('/blob-header.svg');
    background-repeat: no-repeat;
    background-size: 135%;
    font-size: .9375em;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

section {
    margin: 1.5rem 1rem;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}


// Buttons

.btn,
.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;

    &.disabled,
    &:disabled {
        background-color: $color-grey-light;
        border-color: $color-grey-light;
        color: $color-grey;
    }
}

.btn-link {
    @extend .p-0;
    @extend .shadow-none;
    transition: none;
}

.type-buttons {
    button {
        max-width: 300px;
        width: 100%;
    }

    svg {
        margin-right: 4px;
    }
}


// Alerts

.alert {
    border-radius: 8px;
}

.alert-danger {
    background-color: $color-pink-lightest;
    border-color: $color-pink-lightest;
    color: $color-pink;
}

.alert-info {
    background-color: $color-blue-lightest;
    border-color: $color-blue-lightest;
    color: $color-blue;
}


// Cards

.card {
    border: none;
    border-radius: 8px;
}

.card table {
    margin-top: -1rem;
    margin-bottom: -1rem;
}

.card-title {
    padding: 1.5rem 1.25rem 0;
}


// Tabs

.nav-tabs .nav-item {
    border-color: $color-grey-lighter;
    margin-left: 3px;
    margin-right: 3px;
}

.nav-tabs .nav-item:first-child {
    margin-left: 0;
}

.nav-tabs .nav-item:hover {
    background-color: $color-white !important;
}

.nav-tabs .nav-item.active {
    background-color: $color-white;
    border-bottom-color: $color-white;
    color: $color-blue;
    font-weight: bold;
}

.nav-tabs .nav-item:not(.active) {
    background-color: $color-grey-lightest;
    color: $color-blue;
}

.tab-content {
    padding: 1.5rem 0 0;
}


// Tables

.table {
    color: $color-blue;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.table.table-sm {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

.table-hover {
    tbody {
        tr:hover {
            background-color: $color-grey-lightest;
            color: $color-blue;

            &.no-hover {
                background-color: white;
            }
        }
    }
}

// Modals

.modal-footer {
    .card {
        width: 100%;
    }
}
