@import '@root/colours.scss';

img.logo {
    max-width: 250px;
}

.nav-pills {
    .nav-link {
        padding: 0.5rem 1rem !important;

        @media (min-width: 768px) {
            border-radius: 8px 0 0 8px !important;
        }

        &:not(.active) {
            background-color: $color-purple-lightest !important;

            @media (min-width: 768px) {
                margin-right: 0.25rem;
            }

            &.disabled {
                background-color: $color-grey-lighter !important;
            }
        }

        &.active {
            background-color: $color-white !important;
        }
    }
}
