@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '@root/colours.scss';

.input-group-password {
    @import '~bootstrap/scss/forms/input-group.scss';

    input {
        &.error {
            + button {
                border-color: $color-red;
                background-color: $color-red-lighter;
                color: $color-red;
            }
        }
    }

    button {
        border-width: 1px !important;

        svg {
            display: block;
        }
    }
}
