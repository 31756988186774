@import '@root/colours.scss';

.react-select-container {
    .react-select {
        &__menu {
            z-index: 2;
        }

        &__placeholder,
        &__single-value,
        &__option, {
            color: $color-blue;
        }

        &__control--menu-is-open {
            .react-select__single-value {
                color: $color-grey-light;
            }
        }

        &__option--is-focused {
            background-color: $color-blue-lighter;
        }

        &__option--is-selected {
            background-color: $color-blue;
            color: $color-white;
        }
    }
}
