@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_popover.scss';

@import '@root/colours.scss';

input.error,
select.error,
input.error:focus,
select.error:focus {
    border-color: $color-red;
}

.popover {
    font-size: 12px !important;


    .popover-arrow {
        left: -5px !important;
    }

    .popover-body {
        color: $color-blue;
        padding: 0.7rem;
    }
}

.form-switch {
    .form-check-label {
        margin: 0;
    }

    .form-check-input {
        -webkit-appearance: none;
        appearance: none;
        width: 3em;
        height: 1.5em;
        margin: 0;
        vertical-align: top;
        border: 1px solid $color-grey-light;
        border-radius: 2em;
        background-image: escape-svg($form-switch-bg-image);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        @include transition($form-switch-transition);

        &:focus {
            outline: 0;
        }

        &:disabled {
            opacity: .5;
        }

        &:checked {
            background-color: $color-green;
            border-color: $color-green;
            background-position: $form-switch-checked-bg-position;
            background-image: escape-svg($form-switch-checked-bg-image);
        }
    }
}