@import '../../colours.scss';


// Header


.navbar {
    h1 {
        color: $color-white;

        @media (max-width: 768px) {
            padding-top: 0.5rem;
            text-align: center;
        }

        @media (min-width: 768px) {
            font-size: 3rem;
            line-height: 70px;
        }
    }

    img {
        max-width: 80%;
    }
}


// Sidebar navigation


.sidebar-nav {
    @media (min-width: 768px) {
        padding-top: 1.5rem;
        padding-right: 0 !important;
    }

    .nav-link {
        background: $color-grey-lighter;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        color: $color-blue;
        font-family: 'Arial Rounded', 'Arial Black', Gadget, sans-serif;
        white-space: nowrap;

        @media (min-width: 768px) {
            margin: 2px;
        }

        &.active {
            background: $color-white;

            @media (min-width: 768px) {
                margin-right: -3px;
            }
        }

        .title {
            font-size: .7rem;
        }

        .body {
            line-height: 2rem;

            span {
                display: inline-block;
                margin-right: 10px;

                small {
                    font-size: 0.6rem;
                }

                &.h5 {
                    position: relative;
                    top: 0.24rem;
                }
            }
        }
    }
}


// Loader box


.loader-box {
    max-width: 400px;
}


// Main Content


.main-content {
    @media (min-width: 768px) {
        padding-left: 2px !important;
    }

    .container {
        background: $color-white;
        border-radius: 12px;

        @media (max-width: 768px) {
            margin-top: 1rem;
        }

        @media (min-width: 768px) {
            min-height: 400px;
            padding: 25px;
        }
    }
}
